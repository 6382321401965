<template>
  <div v-show="this.inLoader" class="report-hol" style="margin-top: 50px;">
        <div  class="before scanning-block text-center py-5">
            <PulseLoader  style="display:inline-block" />
        </div>
      </div>
      <div v-show="!this.inLoader">
  <div class="visual-holder">
    <div class="row">
      <div class="col-12">
        <h3 class="mb-5">Consent Banner Settings</h3>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
        <div class="row mx-0">
          <div class="col-12 p-0">
            <div class="row mx-0">
              <div class="col-12 col-sm-12 col-md-8 p-0">
                <p class="mb-3">Modes</p>
    <div class="row">
        <div class="ml-3 mb-3">
            <div class="theme-container">
               <button class="modes"> 
                <img v-if="modes === 1" src="@/assets/images/imageb1.png" style="height: 90px; " @click="setStyleForBanner">
                <img v-else src="@/assets/images/imagea4.png" style="height: 90px; " @click="setStyleForBanner">
               </button>
            </div>
        </div>
        <div class=" mb-3 ml-3">
            <div class="theme-container">
               <button class="modes">
                <img v-if="modes === 2" class="modes" src="@/assets/images/imageb2.png" style="height: 90px;" @click="setStyleForBannerDark">
                <img v-else class="modes" src="@/assets/images/imagea5.png" style="height: 90px;" @click="setStyleForBannerDark">
              </button>
            </div>
        </div>
        <div class=" mb-3 ml-3">
            <div class="theme-container">
              <button class="modes">
                <img v-if="modes === 3" class="modes" src="@/assets/images/imageb3.png" style="height: 90px;" @click="scrollToBottom" >
                <img v-else class="modes" src="@/assets/images/imagea6.png" style="height: 90px;" @click="scrollToBottom" >
              </button>
          </div>
        </div>
    </div>
</div>

              <!-- <div class="col-12 col-sm-12 col-md-4 p-0">
                <p class="mb-3">Custom Theme</p>
                <ul class="list-unstyled mb-3 p-0 Custom-theme-pick">
                  <li
                    @click="BannerSetting = !BannerSetting"
                    class="bg-transparent"
                  ></li>
                </ul>
              </div> -->
            </div>
          </div>

          <div>
            <defaultBannerLayout v-if="banner_type === 'default' || banner_type === 'universal' "></defaultBannerLayout>
            <ccpaBannerLayout v-if="banner_type === 'side_one_ccpa' || banner_type === 'exerto'"></ccpaBannerLayout>
            <!-- <exertoBannerLayout v-if="banner_type === 'exerto'"></exertoBannerLayout> -->
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
        <div class="row mx-0 mb-4">
          <div
            class="col-12 p-0 banner-display-block seers-cmp-cookie-data-hol position-relative"
          >
          
          <!-- bannerColorClasses, -->
          <visualDefaultBanner v-if="current_banner.name === 'default'"></visualDefaultBanner>
          <!-- <visualCcpaBanner v-if="current_banner.name === 'universal'"></visualCcpaBanner> -->
          <visualUniversalBanner v-if="current_banner.name === 'universal'"></visualUniversalBanner>
          <visualExertoBanner v-if="current_banner.name === 'exerto'"></visualExertoBanner>
            <!-- <div
              id="SeersCMPBannerMainBar"
              class="seers-cmp-banner-bar"
              :class="[bannerColorClasses, bannerPositionClasses]"
            >
              <DefaultbBanner></DefaultbBanner>
            </div> -->
          </div>
        </div>
        <div class="row mx-0 mb-3">
          <div
            class="col-12 p-0 d-flex justify-content-center align-align-items-center"
          >
            <button class="btn btn-white-bg text-capitalize" @click="bannerShow" style="width: 132px;">
              <i class="fa fa-eye mr-2 text-blue" aria-hidden="true"></i>
              {{preview_btn}}
            </button>
            <button class="btn btn-green-bg text-capitalize fixed-preview" @click="bannerShow">
              <i class="fa fa-eye mr-2 text-white" aria-hidden="true"></i>
              {{preview_btn}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="w-100">
      <div class="row mb-3">
        <div
          class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 control-label mb-0 d-flex justify-content-start align-items-center"
        >
          <label class="mb-2 control-label pr-0">Regulation Configurations</label>

          <i
            aria-hidden="true"
            class="fa fa-info-circle ml-2 info-tooltip-color"
          >
            <span class="pre-tooltip-text" style="text-align: center !important;">
              Configure the banner style and content by selecting the regulation
            </span></i
          >
        </div>
        <div
          class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mb-2 d-flex align-items-center"
        >
          <label class="toggle-box"
            ></label>
          <!---->
        </div>
      </div>
      <div class="row mb-3">
        <div
          class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 control-label mb-0 d-flex justify-content-start align-items-center"
        >
          <label class="mb-2 control-label pl-4 pr-0">GDPR</label>

          <i
            aria-hidden="true"
            class="fa fa-info-circle ml-2 info-tooltip-color"
          >
            <span class="pre-tooltip-text" style="text-align: center !important;">
              Configure GDPR/ Opt-in banner style and content
            </span></i
          >
        </div>
        <div
          class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mb-2 d-flex align-items-center"
        >
          <label class="toggle-box"
            ><input
              id="badge"
              type="checkbox"
              class="toggle-checkbox" @change="checkPlan('default', $event)"  :checked="banner_type == 'default' || banner_type == 'universal' ? true: false" />
            <div class="toggle-switch"></div
          ></label>
          <!---->
        </div>
      </div>
      <div class="row mb-3">
        <div
          class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 control-label mb-0 d-flex justify-content-start align-items-center"
        >
          <label class="mb-2 control-label pl-4 pr-0">Do Not Sell</label>

          <i
            aria-hidden="true"
            class="fa fa-info-circle ml-2 info-tooltip-color"
          >
            <span class="pre-tooltip-text" style="text-align: center !important;">
              Configure Do Not Sell banner style and content
            </span></i
          >
        </div>
        <div
          class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mb-2 d-flex align-items-center"
        >
          <label class="toggle-box"
            ><input
              id="badge"
              type="checkbox"
              class="toggle-checkbox" @change="checkPlan('side_one_ccpa', $event)" :checked="banner_type == 'side_one_ccpa' || banner_type == 'exerto' ? true: false" />
            <div class="toggle-switch"></div
          ></label>
          <!---->
        </div>
      </div>
      <div class="row mb-3" v-if="banner_type == 'side_one_ccpa' || banner_type == 'exerto'">
  <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 control-label mb-0 d-flex justify-content-start align-items-center">
    <label class="mb-2 control-label pr-0">Email</label>
    <i aria-hidden="true" class="fa fa-info-circle ml-2 info-tooltip-color">
      <span class="pre-tooltip-text">Show or hide email input on banner</span>
    </i>
  </div>
  <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mb-2 d-flex align-items-center">
    <label class="toggle-box">
      <input
        id="email_section"
        type="checkbox"
        :disabled="all_toggle_btn === false  || shouldBeDisabled"
        v-model="form.email_section"
        class="toggle-checkbox"
        @change="checkPlan('email_section', $event)"
      />
      <div class="toggle-switch"></div>
    </label>
  </div>
</div>

<div class="row mb-3" v-if="banner_type == 'side_one_ccpa' || banner_type == 'exerto'">
  <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 control-label mb-0 d-flex justify-content-start align-items-center">
    <label class="mb-2 control-label pr-0">Policy URL</label>
    <i aria-hidden="true" class="fa fa-info-circle ml-2 info-tooltip-color">
      <span class="pre-tooltip-text">Show or hide Policy URL</span>
    </i>
  </div>
  <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mb-2 d-flex align-items-center">
    <label class="toggle-box">
      <input
        id="policy_url_section"
        type="checkbox"
        :disabled="all_toggle_btn === false  || shouldBeDisabled"
        v-model="form.policy_url_section"
        class="toggle-checkbox"
        @change="checkPlan('policy_url_section', $event)"
      />
      <div class="toggle-switch"></div>
    </label>
  </div>
</div>
<div v-if="form.policy_url_section && (banner_type == 'side_one_ccpa' || banner_type == 'exerto')" class="form-group row mb-3">
    <div
      class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 control-label mb-0 d-flex justify-content-start align-items-center"
    >
      <label class="mb-4 control-label"
        >Policy Link

        <span class="pre-tooltip-text">
          Add policy link
        </span>
      </label>
      <a
        class="mb-4"
        target="_blank"
        href="https://seerssupport.zendesk.com/hc/en-us/articles/11151102061084"
        ><i
          aria-hidden="true"
          class="fa fa-question-circle text-gray-dark ml-2 position-relative"
        >
        </i
      ></a>
    </div>
    <div
      class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mb-2 d-flex align-items-center"
    >
      <input
        class="form-control mb-4"
        style="width: 400px;"
        type="text"
        placeholder="https://www.example.com/policy-url"
        name="cookie_policy_url"
        autocomplete="off"
        :disabled="all_toggle_btn === false ? true : false"
        v-model="form.policy_url"
      />
    </div>
  </div>
  <div class="form-group row mb-3" v-if="(form.policy_url_section || form.email_section) && (banner_type == 'side_one_ccpa' || banner_type == 'exerto')">
        <div
          class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 control-label mb-0 d-flex justify-content-start align-items-center"
        >
          <label class="mb-2 control-label pr-0">Restrict Main Section</label>

          <i
            aria-hidden="true"
            class="fa fa-question-circle text-gray-dark ml-2 position-relative"
          >
            <span class="pre-tooltip-text">
              Show or hide Main Section Of Do Not Sell Banner
              <!-- <a
								href="https://support.seersco.com/en/what-is-wcag-2.0-aa"
								>Learn more...</a
							> -->
            </span></i
          >
        </div>
        <div
          class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mb-2 d-flex align-items-center"
        >
          <label class="toggle-box"
            ><input
              id="restrict_main_cpra"
              name="restrict_main_cpra"
              type="checkbox"
              class="toggle-checkbox"
              value="true"
              :disabled="all_toggle_btn === false ? true : false || shouldBeDisabled"
              v-model="form.restrict_main_cpra"
              @change="checkPlan('restrict_main_cpra', $event)" />
            <div class="toggle-switch"></div
          ></label>
        </div>
      </div>

    </div>
    <div class="w-100">
      <div class="row mb-3">
        <div
          class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 control-label mb-0 d-flex justify-content-start align-items-center"
        >
          <label class="mb-2 control-label pr-0">Reject button</label>

          <i
            aria-hidden="true"
            class="fa fa-info-circle ml-2 info-tooltip-color"
          >
            <span class="pre-tooltip-text">
              Show/ hide Reject Button on banner
            </span></i
          >
        </div>
        <div
          class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mb-2 d-flex align-items-center"
        >
          <label class="toggle-box"
            ><input
              id="badge"
              type="checkbox"
              class="toggle-checkbox"
              :disabled="all_toggle_btn === false ? true : false  || shouldBeDisabled"
              v-model="form.allow_reject"
              checked="checked"
              @change="checkPlan('allow_reject', $event)" />
            <div class="toggle-switch"></div
          ></label>
          <!---->
        </div>
      </div>
     
     
      <div class="form-group row mb-3">
        <div
          class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 control-label mb-0 d-flex justify-content-start align-items-center"
        >
          <label class="mb-2 control-label pr-0">Cookie Details</label>

          <a
        class="mb-1"
        target="_blank"
        href="https://seerssupport.zendesk.com/hc/en-us/articles/11101284111004"
        ><i
            aria-hidden="true"
            class="fa fa-question-circle text-gray-dark ml-2 position-relative"
          >
            <span class="pre-tooltip-text">
              Cookie Details
              <!-- <a
								href="https://support.seersco.com/en/what-is-wcag-2.0-aa"
								>Learn more...</a
							> -->
            </span></i
          ></a>
        </div>
        <div
          class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mb-2 d-flex align-items-center"
        >
          <label class="toggle-box"
            ><input
              id="drill_down"
              name="drill_down"
              type="checkbox"
              class="toggle-checkbox"
              value="true"
              :disabled="all_toggle_btn === false ? true : false || shouldBeDisabled"
              v-model="form.drill_down"
              @change="checkPlan('drill_down', $event)" />
            <div class="toggle-switch"></div
          ></label>
        </div>
      </div>
      <div class="form-group row mb-3">
        <div
          class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 control-label mb-0 d-flex justify-content-start align-items-center"
        >
          <label class="mb-2 control-label pr-0">Restrict Page Reload</label>

          <i
            aria-hidden="true"
            class="fa fa-question-circle text-gray-dark ml-2 position-relative"
          >
            <span class="pre-tooltip-text">
              Restrict Page Reload
              <!-- <a
								href="https://support.seersco.com/en/what-is-wcag-2.0-aa"
								>Learn more...</a
							> -->
            </span></i
          >
        </div>
        <div
          class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mb-2 d-flex align-items-center"
        >
          <label class="toggle-box"
            ><input
              id="deny_reload"
              name="deny_reload"
              type="checkbox"
              class="toggle-checkbox"
              value="true"
              :disabled="all_toggle_btn === false ? true : false || shouldBeDisabled"
              v-model="form.deny_reload"
              @change="checkPlan('deny_reload', $event)" />
            <div class="toggle-switch"></div
          ></label>
        </div>
      </div>
      <div class="row mb-3">
        <div
          class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 control-label mb-0 d-flex justify-content-start align-items-center"
        >
          <label class="mb-2 control-label pr-0">Show powered by Seers</label>

          <i
            aria-hidden="true"
            class="fa fa-info-circle ml-2 info-tooltip-color"
          >
            <span class="pre-tooltip-text">
              Show or hide "powered by Seers" on second layer of banner
            </span></i
          >
        </div>
        <div
          class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mb-2 d-flex align-items-center"
        >
          <label class="toggle-box"
            ><input
              id="poweredby"
              type="checkbox"
              class="toggle-checkbox"
              v-model="form.powered_by"
              :disabled="all_toggle_btn === false ? true : false || shouldBeDisabled"
              @change="checkPlan('powered_by', $event)" />
            <div class="toggle-switch"></div
          ></label>
          <!---->
        </div>
      </div>
      <div class="form-group row mb-3">
        <div
          class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 control-label mb-0 d-flex justify-content-start align-items-center"
        >
          <label class="mb-2 control-label pr-0">NVDA Accessibility</label>

          <i
            aria-hidden="true"
            class="fa fa-question-circle text-gray-dark ml-2 position-relative"
          >
            <span class="pre-tooltip-text">
              Accessibility by NVDA
              <!-- <a
								href="https://support.seersco.com/en/what-is-wcag-2.0-aa"
								>Learn more...</a
							> -->
            </span></i
          >
        </div>
        <div
          class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mb-2 d-flex align-items-center"
        >
          <label class="toggle-box"
            ><input
              id="nvda_access"
              name="nvda_access"
              type="checkbox"
              class="toggle-checkbox"
              value="true"
              :disabled="all_toggle_btn === false ? true : false || shouldBeDisabled"
              v-model="form.nvda_access"
              @change="checkPlan('nvda_access', $event)" />
            <div class="toggle-switch"></div
          ></label>
        </div>
      </div>
      <div class="row mb-3">
        <div
          class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 control-label mb-0 d-flex justify-content-start align-items-center"
        >
          <label class="mb-2 control-label pr-0">Fonts</label>

          <i
            aria-hidden="true"
            class="fa fa-info-circle ml-2 info-tooltip-color"
          >
            <span class="pre-tooltip-text">
              Change the font of Text on the banner
            </span></i
          >
        </div>
        <div
          class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mb-2 d-flex align-items-center"
        >
               
          <div class="d-flex"  @click="checkPlan('font_style', null)">
            <div class="font-family-cookie"  >
              <select
                :disabled="hide_font === true"
                name=""
                class="font-family-cookie form-control " :class="banner_customization == 0 ? 'ft' : ''"
                v-model="font_style"
                @change="style_size($event)"
              >
                <option v-for="font in  fontsList" :key="font.value">{{font.value}}</option>
              </select>
            </div>
            <div class="font-size-cookie ml-3">
              <select
              :disabled="hide_font === true"
                name="consent_type"
                class="font-size-cookie form-control" :class="banner_customization == 0 ? 'ft' : ''"
                v-model="font_size"
                @change="style_size($event)"
              >
                <option v-for="size in  sizeList" :key="size.value">{{size.value}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div
          class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 control-label mb-0 d-flex justify-content-start align-items-center"
        >
          <label class="mb-2 control-label pr-0">Select button</label>

          <i
            aria-hidden="true"
            class="fa fa-info-circle ml-2 info-tooltip-color"
          >
            <span class="pre-tooltip-text">
              Select the shape on the buttons that shows on banner
            </span></i
          >
        </div>
        <div
          class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 mb-2 d-flex align-items-center"
        >
          <div class="cookie-select-btn d-flex flex-wrap align-items-center">
            <a
              class="cookie-select-btn-default mr-2 mb-2"
              @click="setButtonStyle('cbtn_default')"
              >Default</a
            >
            <a
              class="cookie-select-btn-flat mr-2 mb-2"
              @click="setButtonStyle('cbtn_flat')"
              >Flat</a
            >
            <a
              class="cookie-select-btn-rounded mr-2 mb-2"
              @click="setButtonStyle('cbtn_rounded')"
              >Rounded</a
            >
            <a
              class="cookie-select-btn-stroke mr-2 mb-2"
              @click="setButtonStyle('cbtn_stroke')"
              >Stroke</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- v-if="BannerSetting" -->
    <div class="w-100" >
      <div class="row mb-5" @click="checkPlan('custom_banner', null)"  id="scrollToBottom">
        <div
          class="col-12 col-sm-12 col-md-12 offset-lg-3 col-lg-8 offset-xl-3 col-xl-8 d-flex flex-wrap"
        >
          <div  id="scrollToBottom"
            v-for="(chunk, i) in this.splitByChunk(this.current_banner.customizeColors,5)" :key="i">
            <div class="flex-fill m-3" :key="i">
              <div v-for="(colorScheme, index) in chunk">
                <div
                  class="d-flex align-items-center justify-content-start pl-0 w-100"
                  :key="index + i"
                >
                  <input
                    v-model="current_banner[Object.keys(colorScheme)[0]]"
                    :name="Object.keys(colorScheme)[0]"
                    type="color"
                    :id="Object.keys(colorScheme)[0]"
                    :disabled="policy_enable.disabled || all_toggle_btn === false ? true : false"
                    style="
                      width: 20px;
                      height: 23px;
                      padding: 0 !important;
                      border-radius: 4px;
                      border: 0 !important;
                    "
                  />
                  <!-- <has-error :form="form" :field="Object.keys(colorScheme)[0]"></has-error> -->
                  <label
                    :for="Object.keys(colorScheme)[0]"
                    class="ml-3 control-label"
                    style="color: #7e7e7e !important; font-size: 13px"
                    :style="policy_enable.line_through"
                    :title="Object.values(colorScheme)[0][1]"
                    >{{ Object.values(colorScheme)[0][0] }}</label
                  >
                  <!-- <input
										name="title_text_color"
										type="color"
										id="title_text_color"
										value="#3A67E0"
										style="
											width: 20px;
											height: 23px;
											padding: 0px !important;
											border-radius: 4px;
											border: 0px !important;
											outline: 0 !important;
											background: transparent !important;
										"
									/> -->
                  <!---->
                  <!-- <label
										for="title_text_color"
										title="This text will be heading appearing in banner"
										class="ml-3 control-label"
										style="
											color: rgb(126, 126, 126) !important;
											font-size: 13px;
										"
										>"Title text color"</label
									> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div
          class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 control-label mb-0 d-flex justify-content-start align-items-center"
        >
          <label class="mb-2 control-label pr-0">Show badge</label>

          <i
            aria-hidden="true"
            class="fa fa-info-circle ml-2 info-tooltip-color"
            ><span class="pre-tooltip-text">
              Show a badge to enable cookie banner appear post consent
            </span></i
          >
        </div>
        <div
          class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mb-2 d-flex align-items-center"
        >
          <label class="toggle-box"
            ><input
              id="badge"
              type="checkbox"
              checked="checked"
              class="toggle-checkbox"
              :disabled="all_toggle_btn === false ? true : false"
              v-model="form.has_badge"
              @change="checkPlan('has_badge', $event)" />
            <div class="toggle-switch"></div
          ></label>
          <!---->
        </div>
      </div>
      <div  v-if="form.has_badge" class="row mb-3">
        <div
          class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 control-label mb-0 d-flex justify-content-start align-items-center"
        >
          <label class="mb-2 control-label pr-0">Banner Timeout</label>

          <i
            aria-hidden="true"
            class="fa fa-info-circle ml-2 info-tooltip-color"
            ><span class="pre-tooltip-text">
              Show a badge to enable cookie banner appear post consent
            </span></i
          >
        </div>
        <div
          class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mb-2 d-flex align-items-center"
        >
        <div class="font-family-cookie" style="width: 230px;">
              <select
                name=""
                class="font-family-cookie form-control " 
                v-model="form.banner_timeout"
                @change="banner_timeout()"
              >
                <option value="0">None</option>
                <option value="10">10 Seconds</option>
                <option value="20">20 Seconds</option>
                <option value="30">30 Seconds</option>
              
              </select>
            </div>
        </div>
      </div>
      <div class="row mb-3" v-if="form.has_badge == true">
        <div
        
          class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 control-label mb-0 d-flex justify-content-start align-items-center"
        >
          <label class="mb-2 control-label pr-0">Badge</label>

          <i
            aria-hidden="true"
            class="fa fa-info-circle ml-2 info-tooltip-color"
          >
            <span class="pre-tooltip-text">
              Add a badge of your choice
            </span></i
          >
        </div>
        <div
          class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-2 d-flex align-items-center"
        >
          <select
            name="banner_logo"
            class="bg-white form-control"
            style="padding: 5px"
            v-model="form.badge_status"
            :disabled="all_toggle_btn === false ? true : false  || shouldBeDisabled"
            v-on:change="setBannerBadge($event)"
            >
            <option value="default" selected="selected">Default Badge</option>
            <option value="custom">Own brand</option>
          </select>
          <!---->
        </div>
      </div>
      <div class="row mb-5"  v-if="form.has_badge == true">
        <div
          class="col-12 col-sm-12 col-md-12 offset-lg-3 col-lg-8 offset-xl-3 col-xl-8 d-flex justify-content-start flex-wrap"
        >
          <div
            class="d-flex justify-content-center align-items-center mr-3 mb-3"
          >
            <div id="dropzonebadge" v-if="form.badge_status === 'custom'">
            
              <div
                class="drop"
                @dragover.prevent
                @drop="onDropBadge"
              >
            
              <label v-if="!badge" class="d-flex flex-column align-items-center justify-content-center text-center p-0">
                  <svg
                    class="mb-2"
                    x="0px"
                    y="0px"
                    viewBox="0 0 471.2 471.2"
                    style="height: 20px; width: 20px"
                  >
                    <g>
                      <path
                        d="M457.7,230.15c-7.5,0-13.5,6-13.5,13.5v122.8c0,33.4-27.2,60.5-60.5,60.5H87.5c-33.4,0-60.5-27.2-60.5-60.5v-124.8
              c0-7.5-6-13.5-13.5-13.5s-13.5,6-13.5,13.5v124.8c0,48.3,39.3,87.5,87.5,87.5h296.2c48.3,0,87.5-39.3,87.5-87.5v-122.8
              C471.2,236.25,465.2,230.15,457.7,230.15z"
                        fill="##c2c2c2"
                      ></path>
                      <path
                        d="M159.3,126.15l62.8-62.8v273.9c0,7.5,6,13.5,13.5,13.5s13.5-6,13.5-13.5V63.35l62.8,62.8c2.6,2.6,6.1,4,9.5,4
              c3.5,0,6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1l-85.8-85.8c-2.5-2.5-6-4-9.5-4c-3.6,0-7,1.4-9.5,4l-85.8,85.8
              c-5.3,5.3-5.3,13.8,0,19.1C145.5,131.35,154.1,131.35,159.3,126.15z"
                        fill="##c2c2c2"
                      ></path>
                    </g>
                  </svg>
                  Upload Images Here <br />
                  By Drag & Drop
                  <input
                    type="file"
                    ref="doc"
                    name="badge"
                    @change="onChangeBadge"
                  /> </label
                ><!--
              -->
                <div
                  class="hidden display-inline align-center"
                  v-else
                  v-bind:class="{ badge: true }"
                >
                  <img :src="badge" alt="" class="img" />
                  
                  <br />
                  <br />
                  <button class="btn btn-blue-bg mx-auto" @click="removeFileBadge">
                    REMOVE
                  </button>
                </div>
              </div>
              <div style="margin-top: 10px;  display: flex; justify-content: center; align-items: center;">
              <span style="font-size: 14px;">Badge Size : 60 x 60</span>
            </div>
            </div>
            <!---->
          </div>
          <div
            class="d-flex justify-content-start align-items-center mb-3" style="width: 100px;"
          >
            <a
              href="javascript:void(0);"
              id="badge_img"
              v-if="form.badge_status === 'custom'"
            >
              <img
                class="img"
                style="
                  width: 200px;
                  max-width: 100%;
                  height: auto;
                  margin-top: 10px;
                "
                :src="form.badge_link"
              />
            </a>
            <a
              href="javascript:void(0);"
              id="badge_img"
              v-if="form.badge_status === 'default'"
            >
              <img
                class="img"
                style="
                  width: 200px;
                  max-width: 100%;
                  height: auto;
                  margin-top: 10px;
                "
                :src="badge_default"
              />
            </a>
          </div>
          <DefaultbBanner v-if="show_dialogue_preview === true && banner_type == 'default'"></DefaultbBanner>
          <ccpaBanner v-if="show_dialogue_preview === true && (banner_type == 'side_one_ccpa')"></ccpaBanner>
          <exertoBanner v-if="show_dialogue_preview === true && banner_type == 'exerto'"></exertoBanner>
        </div>
      </div>
      <div class="row mb-3">
        <div
          class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 control-label mb-0 d-flex justify-content-start align-items-center"
        >
          <label class="mb-2 control-label pr-0">Logo</label>

          <i
            aria-hidden="true"
            class="fa fa-info-circle ml-2 info-tooltip-color"
          >
            <span class="pre-tooltip-text">
              Add a logo of your business
            </span></i
          >
        </div>
        <div
          class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mb-2 d-flex align-items-center"
        >
          <select
            name="banner_logo"
            class="bg-white form-control"
            style="padding: 5px"
            v-model="form.logo_status"
            :disabled="all_toggle_btn === false ? true : false || shouldBeDisabled"
            v-on:change="setBannerLogo($event)"
            >
            <option value="none" selected="selected">No brand</option>
            <option value="seers">Default Logo</option>
            <option value="custom">Own brand</option>
          </select>
          <!---->
        </div>
      </div>
      <div class="row mb-5" >
        <div
          class="col-12 col-sm-12 col-md-12 offset-lg-3 col-lg-8 offset-xl-3 col-xl-8 d-flex justify-content-start flex-wrap"
        >
          <div
            class="d-flex justify-content-center align-items-center mr-3 mb-3"
          >
            <div id="dropzone" v-if="form.logo_status === 'custom'">
            
              <div
                class="drop"
                @dragover.prevent
                @drop="onDrop"
              >
            
              <label v-if="!image" class="d-flex flex-column align-items-center justify-content-center text-center p-0">
                  <svg
                    class="mb-2"
                    x="0px"
                    y="0px"
                    viewBox="0 0 471.2 471.2"
                    style="height: 20px; width: 20px"
                  >
                    <g>
                      <path
                        d="M457.7,230.15c-7.5,0-13.5,6-13.5,13.5v122.8c0,33.4-27.2,60.5-60.5,60.5H87.5c-33.4,0-60.5-27.2-60.5-60.5v-124.8
              c0-7.5-6-13.5-13.5-13.5s-13.5,6-13.5,13.5v124.8c0,48.3,39.3,87.5,87.5,87.5h296.2c48.3,0,87.5-39.3,87.5-87.5v-122.8
              C471.2,236.25,465.2,230.15,457.7,230.15z"
                        fill="##c2c2c2"
                      ></path>
                      <path
                        d="M159.3,126.15l62.8-62.8v273.9c0,7.5,6,13.5,13.5,13.5s13.5-6,13.5-13.5V63.35l62.8,62.8c2.6,2.6,6.1,4,9.5,4
              c3.5,0,6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1l-85.8-85.8c-2.5-2.5-6-4-9.5-4c-3.6,0-7,1.4-9.5,4l-85.8,85.8
              c-5.3,5.3-5.3,13.8,0,19.1C145.5,131.35,154.1,131.35,159.3,126.15z"
                        fill="##c2c2c2"
                      ></path>
                    </g>
                  </svg>
                  Upload Images Here <br />
                  By Drag & Drop
                  <input
                    type="file"
                    ref="doc"
                    name="image"
                    @change="onChange"
                  /> </label
                ><!--
              -->
                <div
                  class="hidden display-inline align-center"
                  v-else
                  v-bind:class="{ image: true }"
                >
                  <img :src="image" alt="" class="img" />
                  <br />
                  <br />
                  <button class="btn btn-blue-bg mx-auto" @click="removeFile">
                    REMOVE
                  </button>
                </div>
              </div>
            </div>
            <!---->
          </div>
          <div
            class="d-flex justify-content-start align-items-center mb-3" style="width: 100px;"
          >
            <a
              href="javascript:void(0);"
              id="banner_img"
              v-if="form.logo_status === 'custom'"
            >
              <img
                class="img"
                style="
                  width: 200px;
                  max-width: 100%;
                  height: auto;
                  margin-top: 10px;
                "
                :src="form.logo_link"
              />
            </a>
            <a
              href="javascript:void(0);"
              id="banner_img"
              v-if="form.logo_status === 'seers'"
            >
              <img
                class="img"
                style="
                  width: 200px;
                  max-width: 100%;
                  height: auto;
                  margin-top: 10px;
                "
                :src="logo_default"
              />
            </a>
          </div>
          <DefaultbBanner v-if="show_dialogue_preview === true && current_banner.name == 'default'"></DefaultbBanner>
          <UniversalBanner v-if="show_dialogue_preview === true && current_banner.name == 'universal'"></UniversalBanner>
          <ccpaBanner v-if="show_dialogue_preview === true && (current_banner.name == 'side_one_ccpa')"></ccpaBanner>
          <exertoBanner v-if="show_dialogue_preview === true && current_banner.name == 'exerto'"></exertoBanner>
        </div>
      </div>
    </div>
    <div class="row m-0 mt-4" >
      <div class="col-12 pt-4 border-top px-0">
        <ul class="list-unstyled d-flex align-items-center p-0 domain-hol mb-0">
          <li class="mr-3 mb-3"><router-link to="get-code" class="btn btn-white-bg">Previous</router-link></li>
          <!-- <li class="mr-3 mb-3"><a class="btn btn-blue-bg">Next</a></li> -->
          <li class="mr-3 mb-3">
            <a class="btn btn-green-bg" :class="button_is_disabled == false || shouldBeDisabled ? 'disabled' : ''" @click.prevent="saveDialogueProperties()">Save Changes</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Swal from "sweetalert2";
import visualDefaultBanner from "@/components/CustoMiZationComponent/visualBoxBanner/visualDefaultBanner.vue"
import visualCcpaBanner from "@/components/CustoMiZationComponent/visualBoxBanner/visualCcpaBanner"
import visualUniversalBanner from "@/components/CustoMiZationComponent/visualBoxBanner/visualUniversalBanner"
import visualExertoBanner from "@/components/CustoMiZationComponent/visualBoxBanner/visualExertoBanner"
import defaultBannerLayout from "@/components/CustoMiZationComponent/bannerDesignLayout/defaultBannerLayout.vue"
import ccpaBannerLayout from "@/components/CustoMiZationComponent/bannerDesignLayout/ccpaBannerLayout.vue"
import exertoBannerLayout from "@/components/CustoMiZationComponent/bannerDesignLayout/exertoBannerLayout.vue"
import DefaultbBanner from "@/components/CustoMiZationComponent/DefaultBanner.vue"
import UniversalBanner from "@/components/CustoMiZationComponent/UniversalBanner.vue"
import ccpaBanner from "@/components/CustoMiZationComponent/ccpaBanner.vue"
import exertoBanner from "@/components/CustoMiZationComponent/exertoBanner.vue"
import { emitter } from "@/eventbus";
import axios from "axios";
import { mapState } from 'vuex';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
export default {
  name: "VisualsC",
  components:{
    visualCcpaBanner,
    visualUniversalBanner,
    visualDefaultBanner,
    visualExertoBanner,
    defaultBannerLayout,
    exertoBannerLayout,
    ccpaBannerLayout,
    DefaultbBanner,
    UniversalBanner,
    ccpaBanner,
    PulseLoader,
    exertoBanner
  },
  data() {
    return {
      // hide_font:false,
      gpc_toogle_section:false,
      regulationToggle:true,
      activeTab: "",
      bannerPositionClasses: "seers-cmp-banner-bar",
      image: "",
      badge:"",
      logo_default:
        "https://seers-application-assets.s3.amazonaws.com/images/logo/seersco-logo.png",
      badge_default:
        "https://cdn.seersco.com/assets/seers-cmp-badge.svg",
      policy_enable: {
        status: true,
        line_through: "",
        disabled: false,
      },
      // current_custom_logo: null,
      chunkSize: 3,
      bannerData: [
        {
          template_name: "template_1",
          template_id: 1,
          title_text_color: "#292929",
          body_text_color: "#292929",
          agree_text_color: "#ffffff",
          disagree_text_color: "#3b6ef8",
          preferences_text_color: "#ffffff",
          agree_btn_color: "#99BBFF",
          disagree_btn_color: "#ffffff",
          preferences_btn_color: "#4C86F9",
          logo_bg_color: "#4C86F9",
          banner_bg_color: "#ffffff",
        },
       
        {
          template_name: "template_25",
          template_id: 25,
          title_text_color: "#cccccc",
          body_text_color: "#cccccc",
          agree_text_color: "#FFFFFF",
          disagree_text_color: "#FFFFFF",
          preferences_text_color: "#FFFFFF",
          agree_btn_color: "#383838",
          disagree_btn_color: "#383838",
          preferences_btn_color: "#ffffff",
          logo_bg_color: "#FBFFFE",
          banner_bg_color: "#36454F",
        },
      ],
      fontsList: [
        { title: "arial", value: "arial" },
        { title: "cursive", value: "cursive" },
        { title: "fantasy", value: "fantasy" },
        { title: "monospace", value: "monospace" },
        { title: "sans-serif", value: "sans-serif" },
        { title: "serif", value: "serif" },
        { title: "none", value: "none" },
        { title: "inherit", value: "inherit" },
        { title: "inter", value: "inter" },
        { title: "spezia", value: "spezia" },
      ],
      sizeList: [
        { title: "8", value: "8" },
        { title: "10", value: "10" },
        { title: "12", value: "12" },
        { title: "14", value: "14" },
        { title: "16", value: "16" },
      ],
    };
  },
  computed: {
    ...mapState('domain',['inLoader' , 'user' , 'assign_role']
    ),
    front_url() {
			return process.env.VUE_APP_FRONT;
		},
    shouldBeDisabled() {
      return !this.user.isSuperAdmin && this.user.superAdminId !== null && this.assign_role === 'reader';
    },
    current_custom_logo: {
      set: function (value) {
        this.$store.state.dialogue.form.logo = value;
      },
      get: function () {
        return this.$store.state.dialogue.form.logo;
      },
		},
    current_custom_badge: {
      set: function (value) {
        this.$store.state.dialogue.form.badge = value;
      },
      get: function () {
        return this.$store.state.dialogue.form.badge;
      },
		},
    cmp_url(){
			return process.env.VUE_APP_ENV_VARIABLE
		},
    language: function() {
      return this.$store.getters['domain/getCurrentLanguage'];
    },
    hide_font: function() {
      return this.$store.getters['domain/getHide_font'];
    },
    
    userId: function () {
      return this.$store.state.domain.userId;
    },
    userToken: function () {
      return this.$store.state.domain.userToken;
    },
    banners: function () {
      return this.$store.state.domain.banners;
    },
    banner_type: function () {
      return this.$store.state.domain.banner_type;
    },
    domain_id: function () {
      return this.$store.getters["domain/getCurrentDomainId"];
    },
    form: function () {
      return this.$store.state.dialogue.form;
    },
    reject_val: function () {
      return this.$store.getters["domain/getFeatureRejectVal"];
    },
    is_reject_allowed: function () {
      return this.reject_val == 1;
    },
    banner_customization: function () {
      return parseInt(
        this.$store.getters["domain/getFeatures"].banner_customization
      );
    },
    is_wcag_banner: function () {
      return parseInt(this.$store.getters["domain/getFeatures"].wcag);
    },
    feature: function () {
      return this.$store.getters["domain/getFeatures"].plan_name;
    },
    apply_wcag_banner: {
      set: function (value) {
        this.$store.commit("domain/setWcagBanner", value);
      },
      get: function () {
        return this.$store.getters["domain/getWcagBanner"];
      },
    },
    powered_by: function () {
      return this.$store.getters["domain/getFeaturePoweredBy"];
    },
    hide_poweredby_allowed: function () {
      return this.powered_by == 1;
      //return true;
    },
    current_banner: function () {
      return this.$store.getters["domain/getCurrentBanner"];
    },
    // cus_banner: function () {
    //   return this.$store.getters["domain/getCustomBanner"];
    // },
    font_style: {
      get: function () {
        return this.$store.getters["domain/getCurrentBanner"].font_style;
      },
      set: function (font_style) {
        if (this.banner_customization === 0) {
          this.displayAlert();
        } else {
          this.current_banner.font_style = font_style;
          // console.log("newech", this.form);
          // console.log("current_bannercurrent_banner", this.current_banner);
        }
      },
    },
    font_size: {
      get: function () {
        return this.$store.getters["domain/getCurrentBanner"].font_size;
      },
      set: function (font_size) {
        if (this.banner_customization === 0) {
          this.displayAlert();
        } else {
          this.current_banner.font_size = font_size;
        }
      },
    },
    logo_branding: function () {
      return parseInt(this.$store.getters["domain/getFeatures"].logo_branding);
    },
    button_is_disabled: function () {
      return this.$store.getters["domain/getButtonIsDisabled"];
    },
    banner_design_presets_limit: function () {
      return parseInt(
        this.$store.getters["domain/getFeatures"].banner_design_presets_limit
      );
    },
    show_dialogue_preview: function () {
      return this.$store.getters["domain/getShowDialoguePreview"];
    },
    preview_btn: function() {
      return this.$store.getters["domain/getPreviewBtn"];
    },
    positions: function() {
      return this.current_banner.mainPositions || [];
    },
    banner: function() {
      return this.$store.getters['domain/getCurrentBanner'];
    },
    custombanner: function() {
      return this.$store.getters['domain/getDefaultbanner'];
    },
    exertobanner: function() {
      return this.$store.getters['domain/getExertobanner'];
    },
    universalbanner: function() {
      return this.$store.getters['domain/getUniversalbanner'];
    },
    button_type() {
      if (this.banner.button_type.includes("default"))
          return "seers-cmp-default-style-btn";
      else if (this.banner.button_type.includes("flat"))
          return "seers-cmp-flat-style-btn";
      else if (this.banner.button_type.includes("rounded"))
          return "seers-cmp-rounded-style-btn";
      else return "seers-cmp-stroke-style-btn";
    },
    dialogue: function() {
      return this.$store.getters['domain/getDialogueProperties'];
    },
    all_toggle_btn: function () {
      return this.$store.state.domain.all_toggle_btn;
    },
    gdpr_banners: function() {
        return Object.values(this.banners).filter((banner) => banner.name === "default");
      // return Object.values(this.banners).filter(
      //         (banner) => !banner.name.includes("_ccpa") && banner.name !== "iab" && !banner.name.includes("exerto")
      // );
    },
    ccpa_banners: function() {
      return Object.values(this.banners).filter((banner) =>banner.name.includes("_ccpa"));
    },
    modes: function () {
      return this.$store.getters["domain/getMode"];
    },
    customBanner: function()  {
			return this.$store.getters['domain/getCustomBanner'];
        },
  },
  
  watch:{
		domain_id: {
			// deep: true,
			handler(){
				this.banner_style();
    // console.log('in watch',this.activeTab);
			}
		},
	},
  // updated() {
  //   this.banner_style();
  //   console.log('in updated',this.activeTab);
  //   console.log("current_banner", this.current_banner);
  // },
  mounted() {
    this.banner_style();
    // console.log('in mounted',this.current_banner);
    
    // console.log("current_banner212");
  },
  methods: {
    saveDialogueProperties(){
      emitter.emit('saveDialogueProperties');
    },
    active_Tab(active){
      // console.log('this.current_banner.position',this.current_banner.position)
      // alert(active)
      if(active === 'seers-cmp-banner-bar' || active === 'seers-cmp-top-bar'){
        this.activeTab = 'BannerStyle';
      }
      if(active === 'seers-cmp-middle-bar'){
        this.activeTab = 'ModalStyle';
      }
      if(active === 'seers-cmp-top-left-bar' || active === 'seers-cmp-left-bar' || active === 'seers-cmp-top-right-bar' || active === 'seers-cmp-right-bar'){
        this.activeTab = 'TooltipStyle';
      }
      // this.activeTab = 'BannerStyle';
      this.current_banner.position = active;
      this.updateConsentSpecific();
    },
    banner_timeout(){
      this.$store.commit("domain/setButtonIsDisabled", true);
    },
    banner_style: function(){
      if(this.current_banner.position === 'seers-cmp-top-bar' || this.current_banner.position === 'seers-cmp-banner-bar'){
        return this.activeTab = 'BannerStyle';
      }
      else if(this.current_banner.position === 'seers-cmp-middle-bar'){
        return this.activeTab = 'ModalStyle';
      }
      else if(this.current_banner.position === 'seers-cmp-top-left-bar' || this.current_banner.position === 'seers-cmp-left-bar' || this.current_banner.position === 'seers-cmp-top-right-bar' || this.current_banner.position === 'seers-cmp-right-bar'){
        return this.activeTab = 'TooltipStyle';
      }
    },
    bannerShow(){
      if(this.show_dialogue_preview == true){
        this.$store.commit('domain/setShowDialoguePreview', false)
        this.$store.commit("domain/setPreviewBtn", 'Preview');
      }
      else{
        this.$store.commit("domain/setPreviewBtn", 'Exit Preview');
        this.$store.commit('domain/setShowDialoguePreview', true)
      }
    },
    displayAlert(){
      this.$store.commit("domain/setShowPricingPopup", true);
    },
    changePosition(banner_position) {
      switch (banner_position) {
        
        // default:
          // this.bannerPositionClasses = "seers-cmp-banner-bar";
          // this.current_banner.position = banner_position;
          // break;
        case "seers-cmp-banner-bar":
          this.current_banner.position = banner_position;
          this.updateConsentSpecific();
          // this.bannerPositionClasses = "seers-cmp-banner-bar";
          break;
        case "seers-cmp-top-bar":
          this.current_banner.position = banner_position;
          this.updateConsentSpecific();
          // this.bannerPositionClasses = "seers-cmp-top-bar";
          break;
        case "seers-cmp-middle-bar":
          this.current_banner.position = banner_position;
          this.updateConsentSpecific();
          // this.bannerPositionClasses = "seers-cmp-middle-bar";
          break;
        case "seers-cmp-top-left-bar":
          this.current_banner.position = banner_position;
          this.updateConsentSpecific();
          // this.bannerPositionClasses = "seers-cmp-top-left-bar";
          break;
        case "seers-cmp-top-right-bar":
          this.current_banner.position = banner_position;
          this.updateConsentSpecific();
          // this.bannerPositionClasses = "seers-cmp-top-right-bar";
          break;
        case "seers-cmp-left-bar":
          this.current_banner.position = banner_position;
          this.updateConsentSpecific();
          // this.bannerPositionClasses = "seers-cmp-left-bar";
          break;
        case "seers-cmp-right-bar":
          this.current_banner.position = banner_position;
          this.updateConsentSpecific();
          // this.bannerPositionClasses = "seers-cmp-right-bar";
          break;
      }
    },
    onDrop: function (e) {
      e.stopPropagation();
      e.preventDefault();
      var files = e.dataTransfer.files;
      this.createFile(files[0]);
      this.updateConsentSpecific();
    },
    onDropBadge: function (e) {
      e.stopPropagation();
      e.preventDefault();
      var files = e.dataTransfer.files;
      this.createFileBadge(files[0]);
      this.updateConsentSpecific();
    },
    onChange(e) {
      var files = e.target.files;
      this.createFile(files[0]);
      this.updateConsentSpecific();
    },
    onChangeBadge(e) {
      var files = e.target.files;
      this.createFileBadge(files[0]);
      this.updateConsentSpecific();
    },
    createFile(file) {
      if (!file.type.match("image.*")) {
        return;
      }
      var img = new Image();
      var reader = new FileReader();
      var vm = this;
      this.image = file;
      let payload = {
        file: this.image,
        domain_id: this.domain_id,
        user_id: this.userId,
        is_cookie_banner: this.form.is_cookie_banner,
      };
      axios
        .post(
          `${this.cmp_url}/api/v2/cookieXray-upload-logo`,
          payload,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("user_token"),
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          this.form.logo_link = response.data.logo_link;
          this.form.logo = response.data.logo;
          this.current_custom_logo = response.data.logo;
        });
      reader.onload = function (e) {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    createFileBadge(file) {
  if (!file.type.match("image.*")) {
    return;
  }

  var img = new Image();
  var reader = new FileReader();
  var vm = this;
  this.badge = file;
  console.log(this.badge);

  // Create FormData object
  let formData = new FormData();
  formData.append('file', this.badge);
  formData.append('domain_id', this.domain_id);
  formData.append('user_id', this.userId);
  formData.append('is_cookie_banner', this.form.is_cookie_banner);

  axios.post(
    `${this.cmp_url}/api/v2/cookieXray-upload-badge`,
    formData,
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("user_token"),
        "Content-Type": "multipart/form-data",
      },
    }
  )
  .then((response) => {
    this.form.badge_link = response.data.badge_link;
    this.form.badge = response.data.badge;
    this.current_custom_badge = response.data.badge;
  })
  .catch((error) => {
    console.error('Error uploading file:', error);
  });

  reader.onload = function (e) {
    vm.badge = e.target.result;
  };
  reader.readAsDataURL(file);
},

    removeFile() {
      this.image = "";
    },
    removeFileBadge() {
      this.badge = "";
    },
    updateConsentSpecific(event) {
      this.$store.commit("domain/setButtonIsDisabled", true);
    },
    style_size(event) {
      let styleSize = event ? event.target.value : "";
      // console.log(styleSize)
      // this.button_is_disabled = false;
      this.form.banners.forEach((banner) => {
        if (banner.is_active === true || banner.is_active === 1) {
          Object.assign(banner, this.current_banner);
        }
      });
      this.updateConsentSpecific();
    },
    setButtonStyle: function (btn_style) {
      if (this.banner_customization === 0 && btn_style != "cbtn_default") {
        this.displayAlert();
        return;
      } else {
        // console.log('okok')
        this.$store.commit("domain/setButtonTypeOfCurrentBanner", btn_style);
        this.form.banners.forEach((banner) => {
          if (banner.is_active === true || banner.is_active === 1) {
            // console.log('this.current_banner', this.current_banner)
            Object.assign(banner, this.current_banner);
          }
        });
        this.updateConsentSpecific();
        return;
      }
    },
    splitByChunk: function (arrayElement, chunkSize) {
      if (!arrayElement) return [];

      let array = [];
      for (var i = 0; i < arrayElement.length; i += chunkSize) {
        array.push(arrayElement.slice(i, i + chunkSize));
      }
      return array;
    },
    bannerChange(model){
      // console.log('modelmodel',model)
      let banner_found = false;
      this.form.banners.forEach((banner) => {
        if (banner.name === model) {
          // alert('1',model)
          // console.log('1',banner);
          if (banner.name === "default"){
          banner.is_active = true;
          }else{
            banner.is_active = false;
          }
          banner.mainPositions = this.banners[banner.name].mainPositions;
          banner.customizeColors = this.banners[banner.name].customizeColors;
          this.$store.commit("domain/setCurrentBanner", banner);
          banner_found = true;
        } 
        // else {
        //   // if (banner.name === this.current_banner.name) {
        //   //   alert('12')
        //   //   console.log('12',this.current_banner);
        //   //   // Object.assign(banner, this.current_banner);
        //   // }
        //   banner.is_active = false;
        // }
      });
      if (!banner_found) {
        // alert('13')
        // console.log('13',this.current_banner);
        // console.log('this.banners[model]', this.banners[model])
        this.form.banners.push(this.banners[model]);
        this.$store.commit("domain/setCurrentBanner", this.banners[model]);
        // console.log('13',this.current_banner);
      }
    },
    checkPlan: async function (option, event) {
      let needToUpgrade = false;
      if (option === "allow_reject" && !this.is_reject_allowed) {
        needToUpgrade = true;
        event.target.checked = true;
        this.form.allow_reject = true;
      } else if (option === "allow_reject") {
        emitter.emit("saveDialogueProperties");
      }
      if (this.banner_customization === 0) {
        this.policy_enable.status = false;
        this.policy_enable.line_through = "text-decoration: line-through";
        this.policy_enable.disabled = true;
        if (option === "has_badge") {
          needToUpgrade = true;
          event.target.checked = false;
          this.form.has_badge = false;
        }
        if (option === "custom_banner") {
          needToUpgrade = true;
        }
        if (option === "font_style") {
          needToUpgrade = true;
          this.$store.commit("domain/setHide_font", true);

        }
      }
      if (this.banner_customization != 0) {
        if (option === "custom_banner") {
          this.updateConsentSpecific();
        }
        if (option === "has_badge") {
          this.updateConsentSpecific();
        }
      }
      //gpc toogle
      if (option === "gpc_toogle") {
        if(event.target.checked){
          await Swal.fire({
            title: "Contact Us",
            html:
              "Contact us to enquire more about GPC settings" ,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3b6ef8",
            cancelButtonColor: "#6cc04a",
            confirmButtonText: "Contact Us",
            cancelButtonText: "Cancel",
          }).then((result) => {
            if (result.value) {
              this.gpc_toogle_section = false;
              window.open(`${this.front_url}/contact`, '_blank');
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              this.gpc_toogle_section = false;
            }
          });
        }
        else{
          event.target.checked = false;
          this.gpc_toogle_section = false;
        }
          event.target.checked = false;
          this.gpc_toogle_section = false;
      }
      //end gpc toogle
      //email section
      if (option === "email_section") {
      if (event.target.checked) {
        this.form.email_section = true;
        this.form.policy_url_section = false;
      } else {
        this.form.email_section = false;
      }
      emitter.emit("saveDialogueProperties");
    }
    if (option === "policy_url_section") {
      if (event.target.checked) {
        this.form.policy_url_section = true;
        this.form.email_section = false;
      } else {
        this.form.policy_url_section = false;
      }
      emitter.emit("saveDialogueProperties");
    }
      //start regulation
      if (option === "default") {
        // needToUpgrade = true;
        if(event.target.checked){
          event.target.checked = true;
          this.bannerChange('default');
          this.$store.commit('domain/banner_type','default')
        }
        else{
          event.target.checked = false;
          this.bannerChange('exerto');
          this.$store.commit('domain/banner_type','exerto')
        }
        this.updateConsentSpecific();
      } else if (option === "side_one_ccpa") {
        if(event.target.checked){
          event.target.checked = true;
          this.bannerChange('exerto');
          this.$store.commit('domain/banner_type','exerto')
        }
        else{
          event.target.checked = false;
          this.bannerChange('default');
          this.$store.commit('domain/banner_type','default')
        }
        this.updateConsentSpecific();
      }
      if (option === "powered_by" && !this.hide_poweredby_allowed) {
        needToUpgrade = true;
        event.target.checked = true;
        this.form.powered_by = true;
      } else if (option === "powered_by") {
        emitter.emit("saveDialogueProperties");
      }
      if (option === "drill_down") {
        this.form.drill_down = event.target.checked ? true : false;
        // this.button_is_disabled = false;
        emitter.emit("saveDialogueProperties");
        }
        if (option === "deny_reload") {
          if(this.feature !== "premium"){
            needToUpgrade = true;
            event.target.checked = false;
          }else{
          this.form.deny_reload = event.target.checked ? true : false;
          // this.button_is_disabled = false;
          emitter.emit("saveDialogueProperties");
          }
      }
        if (option === "nvda_access") {
          // if(this.feature !== "premium"){
          //   needToUpgrade = true;
          //   event.target.checked = false;
          // }else{
          this.form.nvda_access = event.target.checked ? true : false;
          // this.button_is_disabled = false;
          emitter.emit("saveDialogueProperties");
          // }
      }
        if (option === "restrict_main_cpra") {
          // if(this.feature !== "premium"){
          //   needToUpgrade = true;
          //   event.target.checked = false;
          // }else{
          this.form.restrict_main_cpra = event.target.checked ? true : false;
          // this.button_is_disabled = false;
          emitter.emit("saveDialogueProperties");
          // }
      }
      if (needToUpgrade) {
          this.displayAlert();
      }
    },
    setBannerLogo(event) {
      $("#banner_img").show();
      const self = this;
      let selectedValue = event ? event.target.value : "";
      switch (selectedValue) {
        case "seers":
          if (this.logo_branding >= 0) {
            self.form.logo = "seersco-logo.png";
            self.form.logo_status = selectedValue;
            $("#dropzone").hide();
            this.updateConsentSpecific();
            break;
          }
        case "none":
          if (this.logo_branding >= 1) {
            // self.form.logo = null;
            self.form.logo_status = selectedValue;
            $("#banner_img").hide();
            $("#dropzone").hide();
            this.updateConsentSpecific();
          } else {
            self.form.logo =
              self.form.logo == null ? "none.png" : self.current_custom_logo;
            self.form.logo_status = "seers";
            $("#dropzone").show();
            this.$store.commit("domain/setShowPricingPopup", true);
            return;
          }
          break;
        case "custom":
          let newV = this.form.logo_link;
          let a = newV.split('https://seers-application-assets.s3.amazonaws.com/images/logo/')
          let b = a[1] == 'seersco-logo.png' ? 'seersco-logo.png' : a[1];
          if (this.logo_branding >= 2) {
            if(self.form.logo == null && b == 'seersco-logo.png'){
              self.form.logo = 'seersco-logo.png';
            }
            else if(self.form.logo == null && b != 'seersco-logo.png'){
              self.form.logo = a[1];
            }
            else if(b != 'seersco-logo.png'){
              self.form.logo = a[1];
            }
            else{
              self.form.logo = self.current_custom_logo
            }
            // self.form.logo =
            //   self.form.logo == null ? "seersco-logo.png" : self.current_custom_logo;
            self.form.logo_status = selectedValue;
            $("#dropzone").show();
            this.updateConsentSpecific();
          } else {
            self.form.logo =
              self.form.logo == null ? "seersco-logo.png" : self.current_custom_logo;
            self.form.logo_status = "seers";
            // $('#dropzone').show();
            this.$store.commit("domain/setShowPricingPopup", true);
            return;
          }
          break;
        default:
          self.form.logo = "seersco-logo.png";
      }
    },
    setBannerBadge(event) {
      $("#Badge_img").show();
      const self = this;
      let selectedValue = event ? event.target.value : "";
      switch (selectedValue) {
        case "default":
          
            self.form.badge = "icon-cookie.png";
            self.form.badge_status = selectedValue;
            $("#dropzonebadge").hide();
            this.updateConsentSpecific();
            break;
          
        case "none":

            // self.form.logo = null;
            self.form.badge_status = selectedValue;
            $("#badge_img").hide();
            $("#dropzonebadge").hide();
            this.updateConsentSpecific();
          break;
        case "custom":
          let newV = this.form.badge_link;
          let a = newV.split('https://seers-application-assets.s3.amazonaws.com/badges/')
          let b = a[1] == 'icon-cookie.png' ? 'icon-cookie.png' : a[1];
            if(self.form.badge == null && b == 'icon-cookie.png'){
              self.form.badge = 'icon-cookie.png';
            }
            else if(self.form.badge == null && b != 'icon-cookie.png'){
              self.form.badge = a[1];
            }
            else if(b != 'icon-cookie.png'){
              self.form.badge = a[1];
            }
            else{
              self.form.badge = self.current_custom_badge
            }
            // self.form.logo =
            //   self.form.logo == null ? "seersco-logo.png" : self.current_custom_logo;
            self.form.badge_status = selectedValue;
            $("#dropzonebadge").show();
            this.updateConsentSpecific();
          break;
        default:
          self.form.logo = "icon-cookie.png";
      }
    },

    scrollToBottom() {
    this.$store.commit("domain/setMode", 3);    
      const element = document.getElementById("scrollToBottom");
  element.scrollIntoView({ behavior: 'smooth' })
  //     
    },
 
    setStyleForBanner(index, palette) {
      // console.log('palette.template_id',palette.template_id)
        this.current_banner.title_text_color = "#000000";
        this.current_banner.body_text_color = "#000000";
        this.current_banner.agree_text_color = "#ffffff";
        this.current_banner.disagree_text_color = "#ffffff";
        this.current_banner.preferences_text_color ="#3b6ef8";
        this.current_banner.agree_btn_color = "#3b6ef8";
        this.current_banner.disagree_btn_color = "#3b6ef8";
        this.current_banner.preferences_btn_color ="#ffffff";
        this.current_banner.logo_bg_color = "#ffffff";
        this.current_banner.banner_bg_color = "#ffffff";
        this.current_banner.modes = 1;
        this.form.banners.forEach((banner) => {
          if (banner.is_active === true || banner.is_active === 1) {
            Object.assign(banner, this.current_banner);
          }
        });
        this.updateConsentSpecific();
    },

    setStyleForBannerDark() {
      // console.log('palette.template_id',palette.template_id);
        this.current_banner.title_text_color = "#ffffff";
        this.current_banner.body_text_color = "#ffffff";
        this.current_banner.agree_text_color = "#000000";
        this.current_banner.disagree_text_color = "#000000";
        this.current_banner.preferences_text_color ="#000000";
        this.current_banner.agree_btn_color = "#ffffff";
        this.current_banner.disagree_btn_color = "#ffffff";
        this.current_banner.preferences_btn_color ="#ffffff";
        this.current_banner.logo_bg_color = "#4C86F9";
        this.current_banner.banner_bg_color = "#000000";
        this.current_banner.modes = 2;
        this.form.banners.forEach((banner) => {
          if (banner.is_active === true || banner.is_active === 1) {
            Object.assign(banner, this.current_banner);
          }
        });
        this.updateConsentSpecific();
      },
    fontSize(extension = 0) {
      if (extension > 0) {
        return (
            (parseInt(this.banner.font_size) + extension).toString() +
            "px !important"
        );
      }
      return parseInt(this.banner.font_size).toString() + "px !important";
    },
  },
};
</script>

<style scoped>
.ft{
  /* cursor:not-allowed */
}
.drop .btn:hover {
  background-color: #722040;
}

.drop input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.drop .align-center {
  text-align: center;
}


.drop .hidden {
  display: none !important;
}

.drop .hidden.image {
  display: inline-block !important;
}
.drop .hidden.badge {
  display: inline-block !important;
}



.drop .img {
  border: 1px solid #f6f6f6;
  display: inline-block;
  height: auto;
  max-height: 50%;
  max-width: 50%;
  width: auto;
}

.drop {
  width: 250px;
  background: #fff;
  border-radius: 5px;
  border: 2px dashed #c2c2c2 !important;
  border: 2px solid rgba(0, 0, 0, 0.3);
  background: #fff;
  padding: 15px;
}
/* select style */
select.form-control {
  height: 35px !important;
  border: 0;
  border-right: 10px solid #fff;
  outline: 1px solid #c2c2c2;
}
.form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
    border-right: 10px solid #e9ecef;
}
/* end select style */

.fa-question-circle,
.fa-info-circle {
  font-size: 16px !important;
  position: relative;
  margin-top: -0.5rem !important;
  color: #7e7979;
}
label.control-label {
  position: relative;
  padding-right: 0;
  white-space: break-spaces;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #000000 !important;
}
.color-plate-holder {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.color-plate-holder li {
  cursor: pointer;
  outline: 0 !important;
  border: 0 !important;
  width: 24px;
  height: 24px;
  border-radius: 50% !important;

  -webkit-box-shadow: 0px 0px 6px rgb(0 0 0 / 15%);
  -moz-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  -ms-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  -o-shadow: 0px 0px 6px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 6px rgb(0 0 0 / 15%);
}
.banner-btn-holder {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.banner-btn-holder li .btn.btn-banner img {
  width: 18px !important;
  height: 18px !important;
}
.banner-btn-holder li .btn.btn-banner {
  color: #000000;
  background: #ffffff;
  border: 1px solid #c6c7c9;
}
.banner-btn-holder li.banneractive .btn-banner {
  color: #3b6ef8 !important;
  background: #ecf1ff !important;
  border: 1px solid #3b6ef8 !important;
}
.color-plate-holder li input {
  background: transparent;
  outline: 0 !important;
  border: 0 !important;
  width: 24px;
  height: 24px;
}

.btn.btn-white-bg:hover .fa {
  color: #fff !important;
}
.cookie-select-btn a.cookie-select-btn-default {
  padding: 8px 35px;
  text-transform: capitalize;
  color: #fff;
  border-radius: 4px;
  background: #c2c2c2;
  cursor: pointer;
}
.cookie-select-btn a.cookie-select-btn-flat {
  padding: 8px 35px;
  text-transform: capitalize;
  color: #fff;
  background: #c2c2c2;
  cursor: pointer;
}
.cookie-select-btn a.cookie-select-btn-rounded {
  padding: 8px 35px;
  text-transform: capitalize;
  color: #fff;
  border-radius: 16px;
  background: #c2c2c2;
  cursor: pointer;
}
.cookie-select-btn a.cookie-select-btn-stroke {
  padding: 8px 35px;
  text-transform: capitalize;
  color: #7e7e7e;
  border-radius: 4px;
  background: #fff;
  border: 1px solid #c2c2c2;
  cursor: pointer;
}
@media (max-width: 638.99px) {
  .banner-btn-holder {
    flex-wrap: wrap;
  }
}
@media (max-width: 575.99px) {
  .banner-btn-holder li {
    margin-bottom: 15px !important;
  }

  label.control-label {
    font-size: 12px !important;
  }
}
@media (max-width: 478.99px) {
  .banner-btn-holder li {
    margin-right: 0 !important;
    width: 100% !important;
  }
  .banner-btn-holder li button.btn {
    width: 100% !important;
  }
}

/* fixed preview style */
.fixed-preview{
  position: fixed;
  top: 50%;
  transform: rotate(-90deg) translateY(-50%);
right: -65px;
    width: 132px;
}
.color-option {
    margin-right: 10px; /* Adjust as needed for spacing */
    border: 1px solid #ccc; /* Add borders for separation */
    border-radius: 5px; /* Optional: Add rounded corners to the color options */
}


.theme-container {
        position: relative; /* Ensure positioning context */
    }

    .theme-container.bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .modes{
      border: 1px solid transparent !important;

    }
    .modes:active{
      border: 1px solid transparent!important;
      box-shadow: 0 0 10px #c2cff4;
    }
    .modes:focus{
      /* border: 1px solid !important; */
      box-shadow: 0 0 10px #c2cff4;

    }
/* fixed preview style */
</style>
